import React, { useEffect, useState } from 'react'
import { Box, LabelStyled, SpanStyled } from '../Styled/Elements';
import { useTranslation } from 'react-i18next';

const FindValue = () => {
    const { t } = useTranslation()
    const [inputValue, setInputValue] = useState('');
    const [fromUnit, setFromUnit] = useState('Meter');
    const [toUnit, setToUnit] = useState('Kilometer');
    const [outputValue, setOutputValue] = useState('');
    const [resultValue, setResultValue] = useState(null);

    const units = [
        'Meter',
        'Kilometer',
        'Centimeter',
        'Millimeter',
        'Micrometer',
        'Nanometer',
        'Mile',
        'Yard',
        'Foot',
        'Inch',
    ];


    const convertFromMeter = (value, toUnit) => {
        switch (toUnit) {
            case 'Meter':
                return value;
            case 'Kilometer':
                return value / 1000;
            case 'Centimeter':
                return value * 100;
            case 'Millimeter':
                return value * 1000;
            case 'Nanometer':
                return value * 1e9;
            case 'Micrometer':
                return value * 1e6;
            case 'Mile':
                return value / 1609.344;
            case 'Yard':
                return value * 1.09361;
            case 'Foot':
                return value * 3.28084;
            case 'Inch':
                return value * 39.3701;

            // case 'Light_Year':
            //     return value / 9.461e15;
            default:
                return ' ';
        }
    };
    const convertFromKilometer = (value, toUnit) => {
        switch (toUnit) {
            case 'Kilometer':
                return value;
            case 'Meter':
                return value * 1000;

            case 'Centimeter':
                return value * 100000;
            case 'Millimeter':
                return value * 1e6;
            case 'Micrometer':
                return value * 1e9;
            case 'Nanometer':
                return value * 1e12;
            case 'Mile':
                return value / 1.60934;
            case 'Yard':
                return value * 1094;
            case 'Foot':
                return value * 3281;
            case 'Inch':
                return value * 39370.1;
            // case 'Light_Year':
            //     return value / 9.461e12;
            default:
                return '';
        }
    };
    const convertFromCentimeter = (value, toUnit) => {
        switch (toUnit) {
            case 'Meter':
                return value / 100;
            case 'Kilometer':
                return value / 100000;
            case 'Centimeter':
                return value;
            case 'Millimeter':
                return value * 10;
            case 'Micrometer':
                return value * 1e4;
            case 'Nanometer':
                return value * 1e7;
            case 'Mile':
                return value / 160934;
            case 'Yard':
                return value / 91.44;
            case 'Foot':
                return value / 30.48;
            case 'Inch':
                return value / 2.54;
            // case 'Light_Year':
            //     return value / 9.461e17;
            default:
                return '';
        }
    };
    const convertFromMillimeter = (value, toUnit) => {
        switch (toUnit) {
            case 'Meter':
                return value / 1000;
            case 'Kilometer':
                return value / 1e6;
            case 'Centimeter':
                return value / 10;
            case 'Millimeter':
                return value;
            case 'Micrometer':
                return value * 1000;
            case 'Nanometer':
                return value * 1e6;
            case 'Mile':
                return value / 1.609e6;
            case 'Yard':
                return value / 914.4;
            case 'Foot':
                return value / 304.8;
            case 'Inch':
                return value / 25.4;
            // case 'Light_Year':
            //     return value / 9.461e18;
            default:
                return '';
        }
    };
    const convertFromMicrometer = (value, toUnit) => {
        switch (toUnit) {
            case 'Meter':
                return value / 1e6;
            case 'Kilometer':
                return value / 1e9;
            case 'Centimeter':
                return value / 1e4;
            case 'Millimeter':
                return value / 1e3;
            case 'Micrometer':
                return value;
            case 'Nanometer':
                return value * 1e3;
            case 'Mile':
                return value / 1.609e12;
            case 'Yard':
                return value / 9.144e5;
            case 'Foot':
                return value / 3.048e5;
            case 'Inch':
                return value / 2.54e4;
            // case 'Light_Year':
            //     return value / 9.461e21;
            default:
                return '';
        }
    };
    const convertFromNanometer = (value, toUnit) => {
        switch (toUnit) {
            case 'Meter':
                return value / 1e9;
            case 'Kilometer':
                return value / 1e12;
            case 'Centimeter':
                return value / 1e7;
            case 'Millimeter':
                return value / 1e6;
            case 'Micrometer':
                return value / 1e3;
            case 'Nanometer':
                return value;
            case 'Mile':
                return value / 1.609e12;
            case 'Yard':
                return value / 9.144e8;
            case 'Foot':
                return value / 3.048e8;
            case 'Inch':
                return value / 2.54e7;
            // case 'Light_Year':
            //     return value / 9.461e24;
            default:
                return '';
        }
    };
    const convertFromMile = (value, toUnit) => {
        switch (toUnit) {
            case 'Meter':
                return value * 1609.34;
            case 'Kilometer':
                return value * 1.60934;
            case 'Centimeter':
                return value * 160934;
            case 'Millimeter':
                return value * 1.609e6;
            case 'Micrometer':
                return value * 1.609e9;
            case 'Nanometer':
                return value * 1.609e12;
            case 'Mile':
                return value;
            case 'Yard':
                return value * 1760;
            case 'Foot':
                return value * 5280;
            case 'Inch':
                return value * 63360;
            case 'Light_Year':
                return value / 5.879e12;
            default:
                return '';
        }
    };
    const convertFromYard = (value, toUnit) => {
        switch (toUnit) {
            case 'Meter':
                return value * 0.9144;
            case 'Kilometer':
                return value * 0.0009144;
            case 'Centimeter':
                return value * 91.44;
            case 'Millimeter':
                return value * 914.4;
            case 'Micrometer':
                return value * 9.144e5;
            case 'Nanometer':
                return value * 9.144e8;
            case 'Mile':
                return value / 1760;
            case 'Yard':
                return value;
            case 'Foot':
                return value * 3;
            case 'Inch':
                return value * 36;
            default:
                return '';
        }
    };
    const convertFromFoot = (value, toUnit) => {
        switch (toUnit) {
            case 'Meter':
                return value * 0.3048;
            case 'Kilometer':
                return value * 0.0003048;
            case 'Centimeter':
                return value * 30.48;
            case 'Millimeter':
                return value * 304.8;
            case 'Micrometer':
                return value * 304800;
            case 'Nanometer':
                return value * 3.048e8;
            case 'Mile':
                return value / 5280;
            case 'Yard':
                return value / 3;
            case 'Foot':
                return value;
            case 'Inch':
                return value * 12;
            // case 'Light_Year':
            //     return value / 9.461e15;
            default:
                return '';
        }
    };
    const convertFromInch = (value, toUnit) => {
        switch (toUnit) {
            case 'Meter':
                return value * 0.0254;
            case 'Kilometer':
                return value * 2.54e-5;
            case 'Centimeter':
                return value * 2.54;
            case 'Millimeter':
                return value * 25.4;
            case 'Micrometer':
                return value * 25400;
            case 'Nanometer':
                return value * 2.54e7;
            case 'Mile':
                return value / 63360;
            case 'Yard':
                return value / 36;
            case 'Foot':
                return value / 12;
            case 'Inch':
                return value;
            // case 'Light_Year':
            //     return value / 9.461e15;
            default:
                return '';
        }
    };

    const convertUnits = () => {
        const value = parseFloat(inputValue);

        if (isNaN(value)) {
            setOutputValue('');
            return;
        }

        let result;

        switch (fromUnit) {
            case 'Meter':
                result = convertFromMeter(value, toUnit);
                break;
            case 'Kilometer':
                result = convertFromKilometer(value, toUnit);
                break;
            case 'Centimeter':
                result = convertFromCentimeter(value, toUnit);
                break;
            case 'Millimeter':
                result = convertFromMillimeter(value, toUnit);
                break;
            case 'Micrometer':
                result = convertFromMicrometer(value, toUnit);
                break;
            case 'Nanometer':
                result = convertFromNanometer(value, toUnit);
                break;
            case 'Mile':
                result = convertFromMile(value, toUnit);
                break;
            case 'Yard':
                result = convertFromYard(value, toUnit);
                break;
            case 'Foot':
                result = convertFromFoot(value, toUnit);
                break;
            case 'Inch':
                result = convertFromInch(value, toUnit);
                break;
            // Add cases for other units as needed
            default:
                setOutputValue('Invalid unit selected');
                // alert('Invalid unit selected.');
                return;
        }

        setOutputValue(result.toString());


        setResultValue(`${value} ${t(fromUnit)} = ${result.toString()} ${t(toUnit)}`);
    };


    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleFromUnitChange = (e) => {
        setFromUnit(e.target.value);
    };

    const handleToUnitChange = (e) => {
        setToUnit(e.target.value);
    };

    useEffect(() => {
        convertUnits();
    }, [inputValue, fromUnit, toUnit]);

    return (
        <Box className='box-wrapper my-4'>
            <Box className='percentage-field'>
                <Box className='unit-converter'>
                    <LabelStyled>{t('From')}</LabelStyled>
                    <input type="text" value={inputValue} onChange={handleInputChange} />
                    <select value={fromUnit} onChange={handleFromUnitChange}>
                        {units.map((unit) => (
                            <option key={unit} value={unit}>
                                {t(unit)}
                            </option>
                        ))}
                    </select>
                </Box>
                <Box className='button-select'>
                    <button className='btn btn-secondary mt-4' >
                        =
                    </button>
                </Box>
                <Box className='unit-converter'>
                    <LabelStyled>{t('To')}</LabelStyled>
                    <input type="text" value={outputValue} readOnly />
                    <select value={toUnit} onChange={handleToUnitChange}>
                        {units.map((unit) => (
                            <option key={unit} value={unit}>
                                {t(unit)}
                            </option>
                        ))}
                    </select>
                </Box>
            </Box>
            {
                resultValue !== null && (
                    <Box className='mt-4 d-flex align-items-center justify-content-center'>
                        <SpanStyled className='btn btn-danger mx-2'>{t('Result')}: </SpanStyled>
                        <SpanStyled className='btn btn-success mx-2'>{resultValue}</SpanStyled>
                    </Box>
                )
            }
        </Box>

    )
}

export default FindValue